.App {
  text-align: center;
}

p, li, h2, h3, ol {
  text-align: left;
  padding-left: 5vh;
  padding-right: 5vh;
  font-family: "Lucida Console", "Courier New", monospace;
}

a {
  text-align: center;
  padding: 3vh;
  font-family: "Lucida Console", "Courier New", monospace;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
}

.storyboard {
  display: inline;
}

img {
  width: 50vh;
  height: auto;
}

.sketches {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.App-header {
  background-color: #509ec3;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: 900;
  font-family: "Lucida Console", "Courier New", monospace;
  color: white;
}

.App-body {
  background-color: #1b274b;
  background-image: linear-gradient(#1b274b, #509ec3);
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #bf0099;
}
